<template>
  <v-container>
    <v-card outlined>
      <v-card-title class="justify-center">Home Page</v-card-title>
      <v-row>
        <v-col cols="4">
          <v-card-text>
            <router-link to="/">Go To Search Page</router-link>
          </v-card-text>
        </v-col>
        <v-col cols="4">
          <v-card-text>
            <router-link to="#">Some Other Page</router-link>
          </v-card-text>
        </v-col>
        <v-col cols="4">
          <v-card-text>
            <router-link to="#">Some Third Page</router-link>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
export default {
  name: "Home",
};
</script>
